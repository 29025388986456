import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import FosterService from 'src/services/foster';
import ContractPDF from 'src/components/ContractPDF';
import withProfile from 'src/components/withProfile';

const PreviewContract = (props) => {
  const { fosterId } = useParams();
  const [foster, setFoster] = useState(null);

  useEffect(
    () => {
      async function fetchData() {
        if (fosterId && fosterId !== 'undefined') {
          const _foster = await FosterService.getFosterById(fosterId);
          setFoster(_foster);
        }
      }
      fetchData();
    },
    [fosterId]
  );

  return (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <ContractPDF foster={foster} profile={props.profile} />
    </PDFViewer>
  );
};

PreviewContract.propTypes = {
  profile: PropTypes.object
};

export default withProfile(PreviewContract, false);
