import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Input, Select, Button } from 'antd';
import { FosterStatusTxt, TaiwanCountyTxt, Router } from 'src/constants/index';
import { parseDate } from 'src/utils';

import withProfile from 'src/components/withProfile';
import FosterViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class FosterPage extends React.Component {
  constructor() {
    super();
    this.vm = new FosterViewModel();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  getText = (percentage) => {
    if (percentage >= 85) return '超完整';
    if (percentage >= 60) return '完整';
    return '尚未完成';
  };

  columns = [
    {
      title: '狀態',
      key: 'status',
      width: 75,
      render: (data) => FosterStatusTxt[data.status] ?? '-'
    },
    {
      title: '案件編號',
      key: 'serialNumber',
      width: 155,
      render: (data) => data.serialNumber ?? '-'
    },
    {
      title: '貓咪名字',
      key: 'name',
      render: (data) => data.name
    },
    {
      title: '出養人',
      key: 'fosterPerson',
      render: (data) => data.fosterPerson.nickname
    },
    {
      title: '地區',
      key: 'region',
      width: 75,
      render: (data) => TaiwanCountyTxt[data.fosterPerson.region] ?? '-'
    },
    {
      title: '合約版本編號',
      key: 'contractVersion',
      width: 116,
      render: (data) => data.contractVersion
    },
    {
      title: '資訊完整度',
      key: 'completeness',
      width: 102,
      render: (data) => this.getText(data.completeness)
    },
    {
      title: '申請認養數量',
      key: 'applicationCount',
      width: 116,
      render: (data) => data.applicationCount
    },
    {
      title: '首次上架時間',
      key: 'firstHitShelvesAt',
      width: 150,
      render: (data) => parseDate(data.firstHitShelvesAt, '-')
    },
    {
      title: '最近更新時間',
      key: 'updateAt',
      width: 150,
      render: (data) => parseDate(data.updateAt, '-')
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (data) => (
        <Button
          type="link"
          href={`${Router.Client.FosterList}/${data.id}`}
          target="_blank"
          className={styles.link}
        >
          出養詳情
        </Button>
      )
    }
  ];

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.header}>出養查詢與管理</div>

        <div className={styles.search}>
          <div className={styles.flex}>
            <div className={styles.title}>狀態：</div>
            <Select
              placeholder="請選擇"
              allowClear
              style={{ width: 120 }}
              onChange={this.vm.onStatusChange}
              options={
                Object.keys(FosterStatusTxt).map((fosterStatus) => (
                  { value: fosterStatus, label: FosterStatusTxt[fosterStatus] }
                ))
              }
            />
            <div className={styles.title}>地區：</div>
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="請選擇"
              allowClear
              style={{ minWidth: 300 }}
              onChange={this.vm.onRegionChange}
              options={
                Object.keys(TaiwanCountyTxt).map((TaiwanCounty) => (
                  { value: TaiwanCounty, label: TaiwanCountyTxt[TaiwanCounty] }
                ))
              }
            />
          </div>

          <Input.Search
            placeholder="請輸入案件編號、出養人姓名或貓咪名字"
            enterButton="搜尋"
            className={styles.input}
            onSearch={this.vm.onKeywordSearch}
          />
        </div>

        <Table
          columns={this.columns}
          dataSource={this.vm.pager.section}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: this.vm.pager.limit,
            total: this.vm.pager.total,
            position: ['topRight'],
            showSizeChanger: true,
            pageSizeOptions: ['20', '50', '100'],
            onShowSizeChange: this.vm.pager.onLimitChange
          }}
          onChange={this.vm.pager.onPageChange}
          scroll={{ y: 'calc(100vh - 248px)', x: 1280 }}
        />
      </div>
    );
  }
}


export default withProfile(FosterPage, true, true);
