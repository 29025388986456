import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DownloadOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import ContractPDF from 'src/components/ContractPDF';
import ContractEmbeded from 'src/components/ContractEmbeded';
import { AppStateContext } from 'src/stores';

import styles from '../styles.module.scss';


@observer
class ContractTab extends React.Component {

  render() {
    const foster = this.props.vm.foster;

    return (
      <div className={styles.contractTab}>

        <div className={styles.header}>
          貓咪認養合約
          <div>
            <PDFDownloadLink
              document={<ContractPDF foster={this.props.vm.foster} profile={this.props.profile} />}
              fileName="出認養合約.pdf"
            >
              <DownloadOutlined className={styles.icon} />
            </PDFDownloadLink>
            {!this.context.state.isMobile && (
              <Link to={`/preview/${this.props.vm.fosterId}`} target="_blank">
                <FileSearchOutlined className={styles.icon} />
              </Link>
            )}
          </div>
        </div>

        <div className={styles.explain}>
          這裡的合約內容是從刊登貓咪的資料中同步抓取，若需改變合約內容，請從「出養資訊」調整。
          另外，依照法規，「動保法」規定為不可變動或調整的項目唷。在這裡也可以下載或預覽設定好的合約內容。
        </div>

        <ContractEmbeded foster={foster} />
      </div>
    );
  }
}

ContractTab.contextType = AppStateContext;

ContractTab.propTypes = {
  vm: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default ContractTab;
