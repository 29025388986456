import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { parseDate, parseMinguoDate } from 'src/utils';
import {
  ColorationTxt,
  AidsTestTxT,
  VaccineTxt,
  DewormerTxt,
  BehaviorTxt,
  NotificationDeadlineTxt,
  ObservationDurationTxt,
  trialPeriodTxt
} from 'src/constants';
import { v1 as contract } from 'src/constants/contract';
import QRcode from 'src/assets/QRcode.png';

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    backgroundColor: '#FFF',
    fontFamily: 'NotoSansTC',
    fontSize: 12,
    fontWeight: 400,
    padding: 30
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCC',
    borderBottom: 'none',
    height: 'auto'
  },
  tabkeKey: {
    width: '90px',
    padding: 8,
    backgroundColor: '#EEE'
  },
  tabkeValue: {
    padding: 8,
    paddingBottom: 15,
    flex: 1
  },
  spacer: {
    marginBottom: 20
  },
  bold: {
    fontWeight: 700
  },
  header: {
    fontSize: 16,
    textAlign: 'center'
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5
  },
  mb: {
    marginBottom: 8
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 50px 1fr)'
  }
});


// Create Document Component
const V1 = (props) => {
  const { foster, fosterPerson } = props;

  const renderBoolean = (v, truthy = '是', falsy = '否') => {
    const isBoolean = v === true || v === false || v instanceof Boolean;
    if (isBoolean) return v ? truthy : falsy;
    return '-';
  };

  return (
    <Document language="zh_TW">
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.header}>認養合約書</Text>
          <View style={styles.spacer} />
          <Text render={() => `出養案件編號：${foster?.serialNumber ?? ''}`} />
          <Text render={() => `合約編號：${foster?.contractNumber ?? ''}`} />
          <View style={styles.spacer} />
          <Text style={styles.title}>立合約人</Text>
          <Text>本人：                          （簡稱甲方）茲向                          （簡稱乙方）認養動物，詳細資料如下：</Text>
          <View style={styles.spacer} />

          <Text style={styles.title}>認養動物資料</Text>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>動物姓名</Text>
            <Text style={styles.tabkeValue} render={() => foster?.name ?? '-'} />
            <Text style={styles.tabkeKey}>性別</Text>
            <Text style={styles.tabkeValue} render={() => contract.GenderTxt[foster?.gender] ?? '-'} />
            <Text style={styles.tabkeKey}>可能出生日期</Text>
            <Text style={styles.tabkeValue} render={() => parseDate(foster?.birthday, '-', true)} />
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>花色</Text>
            <Text style={styles.tabkeValue} render={() => foster?.coloration.map((c) => ColorationTxt[c]).join(', ') ?? '-'} />
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>晶片號碼</Text>
            <Text style={styles.tabkeValue} render={() => foster?.chipNumber ?? '-'} />
            <Text style={styles.tabkeKey}>是否結紮</Text>
            <Text style={styles.tabkeValue} render={() => renderBoolean(foster?.isNeutered)} />
            <Text style={styles.tabkeKey}>愛滋白血二合一快篩</Text>
            <Text style={styles.tabkeValue} render={() => AidsTestTxT[foster?.AIDStest] ?? '-'} />
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>第一劑疫苗</Text>
            <Text style={styles.tabkeValue} render={() => VaccineTxt[foster?.firstVaccine] ?? '-'} />
            <Text style={styles.tabkeKey}>第二劑疫苗</Text>
            <Text style={styles.tabkeValue} render={() => VaccineTxt[foster?.secondVaccine] ?? '-'} />
            <Text style={styles.tabkeKey}>狂犬疫苗</Text>
            <Text style={styles.tabkeValue} render={() => renderBoolean(foster?.rabiesVaccine, '有施打', '未施打')} />
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>基本血檢</Text>
            <Text style={styles.tabkeValue} render={() => renderBoolean(foster?.hasBloodTest, '有做血檢', '沒有做')} />
            <Text style={styles.tabkeKey}>第一次驅蟲</Text>
            <Text style={styles.tabkeValue} render={() => DewormerTxt[foster?.firstDewormer] ?? '-'} />
            <Text style={styles.tabkeKey}>第二次驅蟲</Text>
            <Text style={styles.tabkeValue} render={() => DewormerTxt[foster?.secondDewormer] ?? '-'} />
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tabkeKey}>行為評估</Text>
            <Text style={styles.tabkeValue} render={() => foster?.behaviorAssessment.map((v) => BehaviorTxt[v]).join(', ') ?? '-'} />
          </View>
          <View style={[styles.tableRow, { height: '2.9in', borderBottom: '1px solid #CCC' }]}>
            <Text style={styles.tabkeKey}>動物健康狀況</Text>
            <Text style={styles.tabkeValue} render={() => foster?.healthCondition?.replace(/(\r\n|\n|\r)/gm, '\n') ?? '-'} />
          </View>

          <Text style={styles.mb}>甲方已充分了解認養動物上述情形，將自行負擔後續飼養照顧事宜。</Text>
        </View>

        <View break>
          <Text style={styles.title}>動保法</Text>
          <Text style={styles.mb}>甲方已年滿20歲，認養人與全體同居人（家人、室友、房東）皆取得飼養共識，並共同承諾遵守動保法之相關規定。</Text>
          <Text style={styles.mb}>1.  須依法絕育(5-25萬)並植入晶片(3000-15000)、施打狂犬疫苗(3-15萬)。</Text>
          <Text style={styles.mb}>2.  認養動物受傷或生病時，必須及時請獸醫給予醫療，禁止私餵成藥。</Text>
          <Text style={styles.mb}>3.  甲方願依規定妥善照管認養動物並採取適當防護措施，善盡保護認養動物之責，如有違反願依動保法處新台幣3-15萬元。</Text>
          <Text style={styles.mb}>4.  甲方願善待認養動物，並提供適當之食物、乾淨之飲水、適當之運動空間，不長期關籠、栓綁在戶外或狹小之空間飼養。</Text>

          <View>
            <Image src={QRcode} style={{ width: 100, aspectRatio: '1 / 1' }} />
            <Text>動保法全文QR Code</Text>
          </View>

          <View style={styles.spacer} />
          <Text style={styles.title}>幸福回報頻率</Text>
          <Text render={() => {
            if (foster?.needReport) {
              return (
                <>
                  為協助乙方了解動物生活情形，甲方願配合以下幸福回報規則：
                  自媒合成功並完成簽約日起，每
                  {contract.ObservationFrequencyTxt[foster.observationFrequency] ?? '-'}
                  於平台分享認養動物生活照片一次，媒合成功並完成簽約日起
                  {ObservationDurationTxt[foster.observationDuration] ?? '-'}
                  後，
                  {foster.stabilityFrequency ? `每${contract.StabilityFrequencyTxt[foster.stabilityFrequency] ?? '-'}於平台分享認養動物生活照片一次。` : '不定期於平台分享認養動物生活照片。'}

                </>
              );
            }
            return '甲方願依平台規定，不定期上傳分享認養動物生活照片。';
          }}
          />

          <View style={styles.spacer} />
          <Text style={styles.title}>動物死亡、轉讓或遺失處理方式</Text>
          <Text render={() => (
            <>
              {foster?.canRehome ? '當認養動物轉讓、走失或死亡時' : '認養動物嚴禁轉讓，當認養動物走失或死亡時'}
              ，甲方需
              {foster?.deathOrLossNotification ? `於${NotificationDeadlineTxt[foster.deathOrLossNotification] ?? '-'}通知乙方知悉，並依法辦理相關登記。` : '依法辦理相關登記。'}
              {foster?.deathOrLossUnlimitedLiability ? '甲方同意承擔一切責任，並支付乙方在協尋、訴訟或醫療期間包含但不限於律師費、裁判費、醫療費、火化費等一切相關費用。' : ''}
            </>
          )}
          />

          <View style={styles.spacer} />
          <Text style={styles.title}>適應期（試養期）及相關規則</Text>
          <Text render={() => (
            <>
              {foster?.trialPeriod ? `約定適應期${trialPeriodTxt[foster.trialPeriod] ?? '-'}。` : ''}
              認養後若不擬續養，甲方需盡速通知乙方，經雙方議定同意後，依約定方式將認養動物交還乙方。部分疾病因有潛伏期感染，動物認養後七天內出現生病情形，可帶回交由乙方請獸醫治療。
            </>
          )}
          />

          <View render={() => (
            foster?.needNeutering && <View style={styles.spacer} />
          )}
          />
          <Text
            style={styles.title}
            render={() => (
              foster?.needNeutering && '限定結紮完成時間'
            )}
          />
          <Text render={() => (
            foster?.needNeutering && (
              `認養時如未完成絕育手術，本人願於 ${parseMinguoDate(foster?.neuteringDeadLine)}前協助動物完成絕育手術，違者可處5 - 25萬罰緩。如因動物體質、健康等問題尚無法進行絕育手術者，應由獸醫提出證明，並於適當情形下完成絕育手術或依據動保法申請免節育。`
            )
          )}
          />

          <View style={styles.spacer} />
          <Text style={styles.bold}>本人於認養動物後，如有醫療行為或發生照顧不週致動物死亡、受傷、傷人等情事，願自行負擔一切法律責任，並不得主張任何權力。</Text>

          <View wrap={false}>
            <View render={() => (
              foster?.others && <View style={styles.spacer} />
            )}
            />
            <Text
              style={styles.title}
              render={() => (
                foster?.others && '出養方自訂補充條款'
              )}
            />
            <Text render={() => foster?.others} />
          </View>

          <View style={styles.spacer} />
          <Text>甲方（認養人資料）</Text>
          <Text>姓名：</Text>
          <Text>身份證字號：</Text>
          <Text>出生年月日：</Text>
          <Text>連絡電話 室內 行動電話：</Text>
          <Text>戶籍地址：</Text>
          <Text>通訊地址：</Text>

          <View style={styles.spacer} />
          <Text>乙方（送養人資料）</Text>
          <Text render={() => `姓名：${fosterPerson?.name ?? ''}`} />
          <Text render={() => `身份證字號／護照號碼／統一編號：${fosterPerson?.idNumber ?? ''}`} />
          <Text>出生年月日：</Text>
          <Text render={() => `連絡電話 室內 行動電話：${fosterPerson?.phone ?? ''}`} />
          <Text>戶籍地址：</Text>
          <Text>通訊地址：</Text>

          <View style={styles.spacer} />
          <Text>本認養合約書一式二份，執於認養人及送養人存證，本合約一經雙方簽訂即具有法律效益，敬請永久收存。</Text>
          <View style={styles.spacer} />
          <Text>中華民國                          年                          月                          日</Text>
        </View>
      </Page>

      {foster?.avatar && (
        <Page size="A4" style={styles.page}>
          <Image src={{ uri: foster.avatar, method: 'GET' }} />
        </Page>
      )}

      {(foster?.bloodTestImg && foster.bloodTestImg.length > 0) && (
        <>
          {foster.bloodTestImg.map((img) => (
            <Page size="A4" style={styles.page} key={img}>
              <Image src={{ uri: img, method: 'GET' }} />
            </Page>
          ))}
        </>
      )}
    </Document>
  );
};

V1.propTypes = {
  foster: PropTypes.object,
  fosterPerson: PropTypes.object
};

export default V1;
