import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import GridTable from 'src/components/GridTable';
import CommentInput from 'src/components/CommentInput';
import CancelInput from 'src/components/CancelInput';
import Cancel from 'src/components/Cancel';
import Comment from 'src/components/Comment';
import Loading from 'src/components/Loading';
import NoPermission from 'src/components/NoPermission';
import ApplicationHeaderBox from 'src/components/ApplicationHeaderBox';
import {
  Router,
  TaiwanCountyTxt,
  GenderTxt,
  ColorationTxt,
  ApplicationStatus,
  CommentType
} from 'src/constants';
import { parseDate } from 'src/utils';

import ApplicationItemVM from './viewModel';
import styles from './styles.module.scss';


@observer
class ApplicationItemPage extends React.Component {
  constructor(props) {
    super(props);
    const applicationId = this.props.router.params.applicationId;
    this.vm = new ApplicationItemVM(applicationId, this.props.profile);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    if (this.vm.isPageLoading || !this.vm.hasPermission) {
      return (
        <>
          {this.vm.isPageLoading && <Loading />}
          {!this.vm.isPageLoading && <NoPermission callback={() => this.props.router.navigate(Router.Client.Home)} />}
        </>
      );
    }


    const foster = this.vm.foster;
    const application = this.vm.application;
    const status = application?.status;
    const coloration = foster?.coloration.map((c) => ColorationTxt[c]).join(', ') ?? '';
    const gender = foster?.gender ? `, ${GenderTxt[foster.gender]}` : '';
    const shouldShowFiles = application && application.status !== ApplicationStatus.InContract && application.contracts?.length;

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '認養申請', link: Router.Client.ApplicationList },
            { title: '申請詳細頁' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>

            <div className={styles.leftCol}>
              <div className={styles.imgBox}>
                <img className={styles.img} src={foster?.images?.[0]} alt="cat" />
              </div>
              <div className={styles.name}>{foster?.name}</div>
            </div>

            <div className={styles.rightCol}>

              <ApplicationHeaderBox
                fosterId={application?.fosterId}
                person={foster?.fosterPerson}
                namePrefix="出養人"
                msgText="聯繫出養人"
                status={status}
              />

              <GridTable
                shortList={[
                  { key: '花色&性別', value: `${coloration}${gender}` },
                  { key: '地區', value: TaiwanCountyTxt[foster?.fosterPerson.region] ?? '-' }
                ]}
                longList={[
                  { key: '提交申請時間', value: parseDate(application?.submitAt, '-') },
                  { key: '申請人的留言', value: application?.message },
                  shouldShowFiles
                    ? {
                      key: '下載簽約檔案',
                      value: (
                        <div className={styles.contractsWrapper}>
                          {application.contracts.map((contract) => {
                            const contractFileName = contract.split(`${this.vm.applicationId}/`)[1];
                            return (
                              <Button
                                type="link"
                                href={contract}
                                target="_blank"
                                className={styles.downloadBtn}
                                key={contract}
                              >
                                <PaperClipOutlined />
                                <span style={{ textAlign: 'left' }}>{contractFileName}</span>
                              </Button>
                            );
                          })}
                        </div>
                      )
                    }
                    : null
                ].filter((el) => !!el)}
              />

              {/* 媒合成功-評價出養人 */}
              {this.vm.isMatchedCommentVisible && (
              <Comment
                userId={foster?.fosterPerson.id}
                commentPairId={this.vm.commentPairId}
                comment={this.vm.matchedComment}
                title="媒合成功-評價出養人"
              />
              )}
              {this.vm.canCreateMatchedComment && (
              <div className={styles.commentInputBox}>
                <CommentInput
                  title="媒合成功-評價出養人"
                  onClick={(props) => this.vm.createComment(props, CommentType.MatchedComment)}
                />
              </div>
              )}
              {/* 取消出養原因 */}
              {(application?.cancelOption || application?.cancelDescription) && (
              <Cancel
                title={this.vm.text}
                cancelOption={application?.cancelOption ?? ''}
                cancelDescription={application?.cancelDescription ?? ''}
              />
              )}
              {/* 媒合取消-評價出養人 */}
              {this.vm.isCanceledVisible && (
              <Comment
                userId={foster?.fosterPerson.id}
                commentPairId={this.vm.commentPairId}
                comment={this.vm.canceledComment}
                title={`${this.vm.text}-評價出養人`}
              />
              )}
              {this.vm.canCreateCanceledComment && (
              <div className={styles.commentInputBox}>
                <CommentInput
                  title={`${this.vm.text}-評價出養人`}
                  onClick={(props) => this.vm.createComment(props, CommentType.CanceledComment)}
                />
              </div>
              )}



              {/* 已申請 */}
              {(status === ApplicationStatus.Applied) && (
              <div className={styles.row}>
                <Button className={styles.btn} onClick={this.vm.resendSms}>尚未收到聯繫？提醒出養人</Button>
                <Button className={styles.btn} onClick={this.vm.cancelApplication}>取消認養</Button>
              </div>
              )}

              {/* 聯繫中/簽約中 */}
              {(status === ApplicationStatus.InDiscussion || status === ApplicationStatus.InContract) && (
              <Button className={styles.btn} onClick={this.vm.cancelApplication}>取消認養</Button>
              )}

              {/* 核對中 */}
              {(status === ApplicationStatus.UnderReview) && (
              <div className={styles.row}>
                <Button className={styles.btn} onClick={this.vm.confirmAdoption}>確認認養</Button>
                <Button className={styles.btn} onClick={this.vm.returnContract}>退回</Button>
                <Button className={cn([styles.btn, styles.bgGray])} onClick={this.vm.cancelApplication}>取消認養</Button>
              </div>
              )}

              {/* 媒合成功 */}
              {(status === ApplicationStatus.Matched) && (
              <>
                {(!this.vm.showCancelInput && !this.vm.showDeathInput) && (
                <div className={styles.row2}>
                  <Button
                    className={cn([styles.btn, styles.bgGray])}
                    onClick={this.vm.OnShowCancelInputChange}
                  >
                    申請退養
                  </Button>
                  <Button
                    className={cn([styles.btn])}
                    onClick={this.vm.OnShowDeathInputChange}
                  >
                    死亡通報
                  </Button>
                </div>
                )}
                {this.vm.showCancelInput && (
                <CancelInput type="cancelAdoption" onClick={this.vm.cancelAdoption} />
                )}
                {this.vm.showDeathInput && (
                <CancelInput type="deathReport" onClick={this.vm.deathReport} />
                )}
              </>
              )}

              {/* 取消出養確認 */}
              {(status === ApplicationStatus.CancelFosterConfirmation) && (
              <div className={styles.row2}>
                <Button className={styles.btn} onClick={this.vm.confirmCancelFoster}>同意</Button>
                <Button className={cn([styles.btn, styles.bgGray])} onClick={this.vm.refuseCancelFoster}>拒絕</Button>
              </div>
              )}

              <div className={styles.text}>{`狀態變更時間：${parseDate(application?.statusUpdateAt, '-')}`}</div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

ApplicationItemPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};


export default withRouter(withProfile(ApplicationItemPage, true));
