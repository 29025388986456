import PropTypes from 'prop-types';

import { getVersion } from 'src/utils/contract';

import V1 from './V1';
import V2 from './V2';
import V3 from './V3';

const ContractEmbeded = (props) => {
  const {
    foster = null
  } = props;

  switch (getVersion(foster)) {
    case '1.0':
      return (<V1 foster={foster} />);
    case '2.0':
      return (<V2 foster={foster} />);
    default:
      // default latest
      return (<V3 foster={foster} />);
  }
};

ContractEmbeded.propTypes = {
  foster: PropTypes.object
};

export default ContractEmbeded;
