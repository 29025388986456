import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, DatePicker, Form, Input, Select, Upload } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

import {
  ColorationTxt,
  GenderTxt,
  AidsTestTxT,
  VaccineTxt,
  DewormerTxt,
  BehaviorTxt,
  NotificationDeadlineTxt,
  ObservationDurationTxt,
  ObservationFrequencyTxt,
  StabilityFrequencyTxt,
  trialPeriodTxt
} from 'src/constants';
import UploadWithCrop from 'src/components/UploadWithCrop';
import { customUploadRequest } from 'src/utils';
import CompletenessService from 'src/services/completeness';
import styles from '../styles.module.scss';


@observer
class InfoTab extends React.Component {

  render() {
    const isDisabled = this.props.vm.isDisabled;

    return (
      <>
        <div className={styles.explain}>
          請盡量完整填寫貓咪的資料以及您期待的出養條件，資料愈完整，愈有機會找到合適的認養人唷！
          另外，您所填寫的資料將會自動生成一份「出認養合約」，包括出養條件以及貓咪資料皆會顯示在合約中。
        </div>

        <div className={styles.explain}>
          <div>★重要小提醒★</div>
          <div>
            <span>1.</span>
            <span>您可以先輸入相關資料並「暫存」於平台中，確認資料完備後，再將貓咪資料從「未上架」改為「上架中」，確認開始進行出養。</span>
          </div>
          <div>
            <span>2.</span>
            <span>請確認您所提供的貓咪資料皆屬實，讓我們一起為貓咪找到真正合適的家人。</span>
          </div>
        </div>

        <Form
          form={this.props.form}
          layout="vertical"
          className={styles.infoTab}
          onValuesChange={this.props.vm.onFormChange}
        >
          <div className={styles.subheading}>
            <div className={styles.title}>第一部分：貓的基本資料</div>
            <div>完整度</div>
            <div
              className={styles.percentage}
              style={{ color: CompletenessService.getColor(this.props.vm.completeness.partOne) }}
            >
              {this.props.vm.completeness.partOne}
            </div>
            <div>％</div>
          </div>
          <Form.Item label="貓咪名字" name="name" rules={[{ required: true, message: '貓咪名字必填' }]}>
            <Input placeholder="請輸入" disabled={isDisabled} maxLength={20} />
          </Form.Item>
          <Form.Item label="性別" name="gender">
            <Select
              placeholder="請選擇"
              allowClear
              disabled={isDisabled}
              options={Object.keys(GenderTxt).map((key) => ({
                value: key,
                label: GenderTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="可能出生日期" name="birthday">
            <DatePicker
              style={{ width: '100%' }}
              placeholder="選擇日期"
              disabled={isDisabled}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </Form.Item>
          <Form.Item label="花色" name="coloration" rules={[{ required: true, message: '花色必填' }]}>
            <Select
              mode="multiple"
              showSearch={false}
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(ColorationTxt).map((key) => ({
                value: key,
                label: ColorationTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="標題" name="title" rules={[{ required: true, message: '標題必填' }]}>
            <Input.TextArea placeholder="一句話代表你的貓咪！" showCount maxLength={40} rows={2} disabled={isDisabled} />
          </Form.Item>
          <Form.Item label="介紹" name="introduction" rules={[{ required: true, message: '介紹必填' }]}>
            <Input.TextArea placeholder="還有什麼想讓認養人知道的呢？" showCount maxLength={2000} rows={8} disabled={isDisabled} />
          </Form.Item>

          <div>上傳貓咪照片（上限5張）</div>
          <div className={cn({ [styles.error]: this.props.vm.showImgError })}>
            {this.props.vm.defaultFosterImages && (
            <UploadWithCrop
              listType="picture-card"
              maxCount={5}
              disabled={isDisabled}
              onChange={this.props.vm.OnImagesUpload}
              defaultFileList={this.props.vm.defaultFosterImages}
            />
            )}
          </div>
          <div className={this.props.vm.showImgError ? styles.showError : styles.hideError}>需上傳至少一張照片</div>

          <div className={styles.subheading}>
            <div className={styles.title}>第二部分：貓的檢疫</div>
            <div>完整度</div>
            <div
              className={styles.percentage}
              style={{ color: CompletenessService.getColor(this.props.vm.completeness.partTwo) }}
            >
              {this.props.vm.completeness.partTwo}
            </div>
            <div>％</div>
          </div>

          <Form.Item label="晶片號碼" name="chipNumber">
            <Input placeholder="ex. A000123" disabled={isDisabled} />
          </Form.Item>

          <div>上傳登錄表格圖片</div>
          {this.props.vm.defaultFosterAvatar && (
          <Upload
            listType="picture-card"
            customRequest={customUploadRequest}
            accept="image/*"
            maxCount={1}
            disabled={isDisabled}
            style={{ border: '5px solid red' }}
            onChange={this.props.vm.OnAvatarUpload}
            defaultFileList={this.props.vm.defaultFosterAvatar}
          >
            <div>Upload</div>
          </Upload>
          )}
          <div style={{ marginBottom: 24 }} />

          <Form.Item label="是否結紮" name="isNeutered" rules={[{ required: true, message: '是否結紮必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '是' : '否'
              }))}
            />
          </Form.Item>
          <Form.Item label="愛滋白血二合一快篩" name="AIDStest" rules={[{ required: true, message: '愛滋白血二合一快篩必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(AidsTestTxT).map((key) => ({
                value: key,
                label: AidsTestTxT[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="預防針第一劑" name="firstVaccine" rules={[{ required: true, message: '預防針第一劑必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(VaccineTxt).map((key) => ({
                value: key,
                label: VaccineTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="預防針第二劑" name="secondVaccine" rules={[{ required: true, message: '預防針第二劑必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(VaccineTxt).map((key) => ({
                value: key,
                label: VaccineTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="狂犬疫苗" name="rabiesVaccine" rules={[{ required: true, message: '狂犬疫苗必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '有施打' : '未施打'
              }))}
            />
          </Form.Item>
          <Form.Item label="基本血檢" name="hasBloodTest" rules={[{ required: true, message: '基本血檢必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '有做血檢' : '沒有做'
              }))}
            />
          </Form.Item>

          {(this.props.vm.defaultBloodTestImg && this.props.vm.showBloodTest) && (
          <>
            <div>上傳血檢報告（至少一張，上限五張圖）</div>
            <div className={cn({ [styles.error]: this.props.vm.showBloodTestImgError })}>
              <Upload
                listType="picture-card"
                customRequest={customUploadRequest}
                accept="image/*"
                maxCount={5}
                disabled={isDisabled}
                onChange={this.props.vm.OnBloodTestImgUpload}
                defaultFileList={this.props.vm.defaultBloodTestImg}
              >
                <div>Upload</div>
              </Upload>
            </div>
            <div className={this.props.vm.showBloodTestImgError ? styles.showError : styles.hideError}>需上傳至少一張照片</div>
          </>
          )}

          <Form.Item label="驅蟲蚤第一次" name="firstDewormer" rules={[{ required: true, message: '驅蟲蚤第一次必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(DewormerTxt).map((key) => ({
                value: key,
                label: DewormerTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="驅蟲蚤第二次" name="secondDewormer" rules={[{ required: true, message: '驅蟲蚤第二次必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(DewormerTxt).map((key) => ({
                value: key,
                label: DewormerTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="動物健康狀態" name="healthCondition" rules={[{ required: true, message: '動物健康狀態必填' }]}>
            <Input.TextArea showCount maxLength={300} rows={10} placeholder="請輸入" disabled={isDisabled} />
          </Form.Item>
          <Form.Item label="行為評估" name="behaviorAssessment">
            <Select
              mode="multiple"
              showSearch={false}
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(BehaviorTxt).map((key) => ({
                value: key,
                label: BehaviorTxt[key]
              }))}
            />
          </Form.Item>

          <div className={styles.subheading}>
            <div className={styles.title}>第三部分：貓的出養條件</div>
            <div>完整度</div>
            <div
              className={styles.percentage}
              style={{ color: CompletenessService.getColor(this.props.vm.completeness.partThree) }}
            >
              {this.props.vm.completeness.partThree}
            </div>
            <div>％</div>
          </div>

          <Form.Item label="A 是否需要幸福回報" name="needReport" rules={[{ required: true, message: '是否需要幸福回報必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '需要' : '不需'
              }))}
            />
          </Form.Item>
          {this.props.vm.showReport && (
          <>
            <Form.Item
              label="B 觀察期幸福回報日程長度"
              name="observationDuration"
              dependencies={['needReport']}
              rules={[
                { required: true, message: '觀察期幸福回報日程長度必填' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('needReport')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error());
                  }
                })
              ]}
            >
              <Select
                allowClear
                placeholder="請選擇"
                disabled={isDisabled}
                options={Object.keys(ObservationDurationTxt).map((key) => ({
                  value: Number.parseInt(key, 10),
                  label: ObservationDurationTxt[key]
                }))}
              />
            </Form.Item>
            <Form.Item
              label="C 觀察期幸福回報頻率"
              name="observationFrequency"
              dependencies={['needReport']}
              disabled={isDisabled}
              rules={[
                { required: true, message: '觀察期幸福回報頻率必填' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('needReport')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error());
                  }
                })
              ]}
            >
              <Select
                allowClear
                placeholder="請選擇"
                disabled={isDisabled}
                options={Object.keys(ObservationFrequencyTxt).map((key) => ({
                  value: Number.parseInt(key, 10),
                  label: ObservationFrequencyTxt[key]
                }))}
              />
            </Form.Item>
            <Form.Item
              label="D 穩定期幸福回報頻率"
              name="stabilityFrequency"
              dependencies={['needReport']}
              rules={[
                { required: true, message: '穩定期幸福回報頻率必填' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if ((value || value === 0) && getFieldValue('needReport')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error());
                  }
                })
              ]}
            >
              <Select
                allowClear
                placeholder="請選擇"
                disabled={isDisabled}
                options={Object.keys(StabilityFrequencyTxt).map((key) => ({
                  value: Number.parseInt(key, 10),
                  label: StabilityFrequencyTxt[key]
                }))}
              />
            </Form.Item>
          </>
          )}
          <Form.Item label="可否再送養" name="canRehome" rules={[{ required: true, message: '可否再送養必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '可' : '不可'
              }))}
            />
          </Form.Item>
          <Form.Item label="死亡或遺失是否須通知送養人" name="deathOrLossNotification" rules={[{ required: true, message: '死亡或遺失是否須通知送養人必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(NotificationDeadlineTxt).map((key) => ({
                value: Number.parseInt(key, 10),
                label: NotificationDeadlineTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="死亡或遺失是否須負無限責任" name="deathOrLossUnlimitedLiability" rules={[{ required: true, message: '死亡或遺失是否須負無限責任必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '是' : '否'
              }))}
            />
          </Form.Item>
          <Form.Item label="適應期" name="trialPeriod" rules={[{ required: true, message: '適應期必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={Object.keys(trialPeriodTxt).map((key) => ({
                value: Number.parseInt(key, 10),
                label: trialPeriodTxt[key]
              }))}
            />
          </Form.Item>
          <Form.Item label="結紮時間限定" name="needNeutering">
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '需限定' : '不限定'
              }))}
            />
          </Form.Item>
          {this.props.vm.showNeuteringDeadLine && (
          <Form.Item
            name="neuteringDeadLine"
            dependencies={['needNeutering']}
            rules={[
              { required: true, message: '必填結紮時間' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue('needNeutering')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error());
                }
              })
            ]}
          >
            <DatePicker style={{ width: '100%' }} placeholder="選擇日期" disabled={isDisabled} />
          </Form.Item>
          )}
          <Form.Item label="是否需要家訪" name="needHomeVisit" rules={[{ required: true, message: '是否需要家訪必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '需要' : '不需'
              }))}
            />
          </Form.Item>
          <Form.Item label="門窗防護" name="needWindowSecurity" rules={[{ required: true, message: '門窗防護必填' }]}>
            <Select
              allowClear
              placeholder="請選擇"
              disabled={isDisabled}
              options={[false, true].map((key) => ({
                value: key,
                label: key ? '需要' : '不需'
              }))}
            />
          </Form.Item>

          <div className={styles.subheading}>
            <div className={styles.title}>出養方自訂補充條款：</div>
          </div>

          <Form.Item name="others">
            <Input.TextArea
              showCount
              maxLength={1000}
              rows={15}
              placeholder="若還有其它您希望在出養合約裡的規範項目，您可以於此區塊內自行補充約定內容。請以繁體正楷字輸入，若內容較多，也建議分段分點編輯，讓內容可以清楚的呈現於合約中。"
            />
          </Form.Item>


          {(!isDisabled) && (
          <Button
            className={styles.saveBtn}
            onClick={this.props.vm.onSave}
            loading={this.props.vm.isSaving}
          >
            儲存
            <RightCircleFilled />
          </Button>
          )}
        </Form>

      </>
    );
  }
}

InfoTab.propTypes = {
  form: PropTypes.object.isRequired,
  vm: PropTypes.object.isRequired
};

export default InfoTab;
